<template>
    <div>
        <!--<div>

    <b-modal hide-footer title="asdf">

        <template #default="{ hide }">
            <p>Modal Body with button</p>
            <b-button @click="hide()">Hide Modal</b-button>
        </template>

        <template #modal-footer="{ ok, cancel }">
            <b>Custom Footer</b>-->
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <!--<b-button size="sm" variant="success" @click="ok()">
        OK
    </b-button>
    <b-button size="sm" variant="danger" @click="cancel()">
        Cancel
    </b-button>-->
        <!-- Button with custom close trigger value -->
        <!--<b-button size="sm" variant="outline-secondary" @click="hide('forget')">
                    Forget it
                </b-button>
            </template>
        </b-modal>
    </div>-->
        <!-- Modal -->
        <!--<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-danger" id="exampleModalLabel">{{title}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Email address</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Password</label>
                            <input type="password" class="form-control" id="exampleInputPassword1">
                        </div>
                        <button type="submit" class="btn btn-primary">Login</button>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>-->
        <!-- Button trigger modal -->

        <MDBModal id="exampleModal"
                  tabindex="-1"
                  labelledby="exampleModalLabel"
                  v-model="testshowModal"
                  :size="modalSize">
            <MDBModalHeader>
                <MDBModalTitle id="exampleModalLabel" v-html="title"></MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody v-html="content"></MDBModalBody>
            <MDBModalFooter>
                <button type="button" class="btn btn-success btn-sm" @click="ok">
                    {{okbuttontext}}
                </button>
                <button type="button" class="btn btn-danger btn-sm" @click="cancel" v-show="confirm">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>

        <!--=============== modal product history and chart ===============-->
        <MDBModal tabindex="-1"
                  v-model="showHistory">
            <MDBModalHeader>
                <MDBModalTitle>
                    <div>History of <b>{{nameHistory}}</b></div>
                    <div v-show="checkFullAcl('Product/ViewChart')">
                        <button type="button" class="btn btn-success btn-sm" @click="getProductChart">
                            <i class="ion-stats-bars me-1"></i>
                            View Chart (Remain)
                        </button>
                    </div>
                </MDBModalTitle>

            </MDBModalHeader>
            <MDBModalBody>
                <div v-for="item in dataHistory" :key="item.SelectedDate">
                    <div>{{item.SelectedDate}} by <b>{{item.UpdatedBy}}</b></div>
                    <div v-show="!item.dataChanged">
                        <span v-show="!item.Created">No change.</span>
                        <span v-show="item.Created" style="color:black">Created</span>
                    </div>
                    <ul v-show="item.dataChanged">
                        <li v-for="(item,i) in item.dataChanged" :key="i">
                            <b style="color:black">{{i}}</b>:  <span v-html="formatChange(i,item)"></span>
                        </li>
                    </ul>
                    <hr />
                </div>
            </MDBModalBody>
        </MDBModal>

        <MDBModal tabindex="-1"
                  v-model="showChartHistory" size="xl">
            <MDBModalHeader>
                <MDBModalTitle>
                    <div>Chart of <b>{{nameHistory}}</b></div>                    
                </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <div style="position: relative; height:40vh;">
                    <canvas id="MonthlyChart"></canvas>
                </div>
            </MDBModalBody>
        </MDBModal>
        <!--============================================================-->

    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    //https://www.npmjs.com/package/diff
    import 'diff';
    const Diff = require('diff');

    import { Chart, registerables } from "chart.js";
    import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
    

    import c from '@/mylib/common'
    //========== BOOSTRAP MODAL
    //https://cdmoro.github.io/bootstrap-vue-3/getting-started/#bundlers
    //https://bootstrap-vue.org/docs/components/modal


    //https://mdbootstrap.com/docs/b5/vue/components/modal/#docsTabsAPI
    import {
        //MDBBtn,
        MDBModal,
        MDBModalBody,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalFooter
    } from 'mdb-vue-ui-kit';


    export default {
        name: 'Modal',        
        data() {
            return {
                codeHistory: '',
                nameHistory: '',
                showHistory: false,
                showChartHistory: false,
                dataHistory: [],
                dataHistoryChart: [],
                ChartInstance:null,
                testshowModal: false
            }
        },
        components: {
            //MDBBtn,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
        },
        computed: mapState({
            title: state => state.view.Modal.title,
            content: state => state.view.Modal.content,
            IsShowModal: state => state.view.Modal.isShow,
            viewHistory: state => state.view.Modal.viewHistory,            
            confirm: state => state.view.Modal.isConfirm,
            classType: function() {
                const type = this.$store.state.view.Modal.type;
                if (type == 'warning') return 'yellow-text';
                else if (type == 'error') return 'red-text';
            },
            modalSize: function () {                
                const size = this.$store.state.view.Modal.size;
                if (size == 'large') return 'lg';
                else if (size == 'medium') return '';
                else return 'sm';
            },
            okbuttontext: function () {                
                return this.confirm ? 'Yes' : 'OK'
            },
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',                
                'call': 'api/call'
            }),
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            formatDate(dstr, notime) {
                let d = new Date(dstr);
                if (d == "Invalid Date") return "";
                return c.formatDate(d, notime);
            },
            formatNumber(n) {
                return c.formatNumber(n);
            },
            formatChange(field, text) {
                let val = text.split("->");
                let val1 = val[0];
                let val2 = val[1];

                if (field == "PeakSold"
                    || field == "RegularSold"
                    || field == "Remain") {
                    val1 = this.formatNumber(val1);
                    val2 = this.formatNumber(val2);
                    val2 = `<b style="color:blue">${val2}</b>`;
                } else {
                    const diff = Diff.diffChars(val1, val2);
                    //console.log(diff); 
                    val1 = '';
                    val2 = '';
                    diff.forEach((part) => {
                        if ('removed' in part == false && 'added' in part == false) {
                            val1 += part.value;
                            val2 += part.value;
                        }
                        else if (part.removed) {
                            val1 += `<b style="color:red">${part.value}</b>`;
                        }
                        else if (part.added) {
                            val2 += `<b style="color:blue">${part.value}</b>`;
                        }

                    });
                }
                return `${val1} => ${val2}`;
            },
            async getHistory() {
                this.loading(true);                
                if (this.dataTableHistory != null) {
                    this.dataTableHistory.destroy();
                }
                const rs = await this.call({ Action: 'Product/ViewHistory', Object: { Code: this.codeHistory } });
                this.loading(false);                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    
                    this.dataHistory = rs.Data;
                    
                    if (Object.keys(this.dataHistory).length>0)
                        this.dataHistory.sort((a, b) => b.SelectedDate.localeCompare(a.SelectedDate));
                    this.showHistory = true;
                }
            },
            async getProductChart() {
                this.loading(true);
                if (this.dataTableHistory != null) {
                    this.dataTableHistory.destroy();
                }
                const rs = await this.call({ Action: 'Product/ViewChart', Object: { Code: this.codeHistory } });
                this.loading(false);
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {                    
                    
                    this.dataHistoryChart = rs.Data;
                    this.showChartHistory = true;
                    setTimeout(this.doProductChart, 200);
                }
            },
            doProductChart() {
                //=============== Last 12 months chart ==========================
                Chart.defaults.animation = true;
                Chart.defaults.responsive = true;
                //const Labels = [];
                const Titles = "Last 12 months inventory";
                const listColor = ["#198754", "#92d3ff", "#ffa2b5"];
                let dataset = {};
                dataset["label"] = 'Remain';
                dataset["backgroundColor"] = listColor[0];
                dataset["borderWidth"] = 0;
                dataset["barPercentage"] = 0.5;
                dataset["data"] = this.dataHistoryChart;

                console.log(this.dataHistoryChart);

                //https://www.youtube.com/watch?v=p3WQQkuEJ3M
                const floatingLabels = {
                    id: 'floatingLabels',
                    afterDatasetsDraw(chart, args, options) {
                        
                        const { ctx, chartArea: { top }, scales: { x, y } } = chart;
                        const { dataIndex, datasetIndex, char } = chart;
                        //console.log(dataIndex, datasetIndex);
                        //ctx.save();
                        ////ctx.fillStyle = 'rgba(255,26,104,1)';
                        //ctx.textAlign = 'center';
                        //ctx.font = 'bolder 12px Arial';
                        //ctx.fillText('test',10,10);
                        return;

                        
                        //for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
                        //    const xPosition = chart.getDatasetMeta(0).data[i].x;
                        //    const yPosition = top+20;
                        //    ctx.save();
                        //    ctx.fillStyle = 'rgba(255,26,104,1)';
                        //    ctx.textAlign = 'center';
                        //    ctx.font = 'bolder 12px Arial';
                        //    ctx.fillText(chart.config.data.datasets[0].data[i], xPosition, yPosition);
                        //}
                    }
                }

                dataset["datalabels"] = {
                    align: 'start',
                    anchor: 'end',
                    offset: function (context) {
                        var index = context.dataIndex;
                        var value = context.dataset.data[index];
                        return index % 2 ? 0 :0;
                    },
                    color: function (context) {
                        var index = context.dataIndex;
                        var value = context.dataset.data[index];
                        return value < 0 ? '#ff0000' :  // draw negative values in red
                            index % 2 ? 'black' :      // else, alternate values in blue and green
                                'blue';
                    },
                    rotation:-90,
                    font: {
                        weight: 'bold',
                        size: 12,

                    },
                };

                const dataSets = [];
                dataSets.push(dataset);
                // for (let i = 11; i >=0; i--) {
                //     const date = new Date(new Date().setMonth(new Date().getMonth()-i));
                //     Labels.push(`${date.getMonth() + 1}/${date.getFullYear()}`);
                // }



                let ctx = document.getElementById("MonthlyChart");
                //https://github.com/apertureless/vue-chartjs/issues/695
                if (this.ChartInstance != null) this.ChartInstance.destroy();
                const _this = this;
            

                this.ChartInstance = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        //labels: Labels,
                        datasets: dataSets,
                    },
                    options: {
                       
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                display: false,
                                position: "bottom",

                                ticks: {
                                    color: "black", // To format the ticks, coming on the axis/lables which we are passing.
                                    font: {
                                        size: 15
                                    },
                                    //align: "end",
                                    //crossAlign:'near',
                                    //https://www.geeksforgeeks.org/how-to-change-text-label-orientation-on-x-axis-in-chart-js/
                                    // Maximum rotation angle for tick labels
                                    maxRotation: 90,
                                    // Minimum rotation angle for tick labels
                                    minRotation: 90,
                                }
                            },
                            y: {
                                display: true,
                               
                                ticks: {
                                    color: "red",
                                    font: {
                                        size: 14
                                    },
                                },
                                offset: 0,
                            }
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                formatter: (value, ctx) => {
                                    //https://apexcharts.com/docs/datalabels/
                                    //https://stackoverflow.com/questions/76552557/how-to-position-positive-value-on-top-and-negative-value-on-bottom-of-stacked-ba

                                    //const { dataIndex, datasetIndex, chart } = context;
                                    //const dataForDataIndex = chart.data.datasets.map(
                                    //    dataset => dataset.data[dataIndex] ?? 0
                                    //);
                                    //const total = dataForDataIndex.reduce((s, x) => s + x)
                                    //// the index of the dataset that contains the last point (at dataIndex)
                                    //// with the same sign as the total - that is the one that should carry
                                    //// the total label
                                    //const datasetIndexLast = dataForDataIndex.findLastIndex(x => x * total > 0);
                                    //context.total = total;
                                    //return datasetIndexLast === datasetIndex ? total.toFixed(2) : null


                                    const { dataIndex, datasetIndex, chart } = ctx;
                                    const label = chart.data.labels[dataIndex];

                                    //ctx.save();
                                    ctx.textAlign = 'center';
                                    ctx.font = 'bolder 12px Arial';
                                    //ctx.fillText('test', 20, 20);
                                    //ctx.save();
                                    //console.log(label, chart.data.datasets[0]);
                                    return label+" "+chart.data.datasets[0].data[label];
                                },
                               

                                //anchor: (context) => {
                                //    return 'start';
                                //   // return context.total > 0 ? 'end' : 'start'
                                //},
                                //align: (context) => {
                                //    return 'bottom';
                                //    //return context.total > 0 ? 'top' : 'bottom';
                                //},

                                clip: false
                            },

                            title: {
                                display: false,
                                align: 'start',
                                text: Titles,
                                color: 'black',
                                padding: {
                                    top: 10,
                                    bottom: 30
                                }
                            },
                            legend: {
                                display: false,
                                labels: {
                                    // This more specific font property overrides the global property
                                    
                                    font: {
                                        size: 14
                                    },
                                    color: 'black',
                                }
                            },
                           
                        }
                    },
                    plugins: [
                        ChartJsPluginDataLabels,
                        //floatingLabels,
                    ]
                });
            },
            ok: function () {                
                this.showModal({ isShow: false,triggerConfirm:true });
            },
            cancel: function() {
                this.showModal({ isShow: false });
            }
        },
        created() {
            Chart.register(...registerables);
        },
        watch: {
            IsShowModal(newVal) {                
                this.testshowModal = newVal;
            },
            testshowModal(newVal) {
                if (newVal==false)
                    this.$store.dispatch("view/showModal", { isShow: newVal});
            },
            viewHistory(newVal) {
                if (newVal.code == '') return;
                this.codeHistory = newVal.code;
                this.nameHistory = newVal.name;
                
                //activate show history modal
                this.getHistory();
            },
            showHistory(newVal) {
                //disable history code
                if (newVal == false)
                    this.$store.dispatch("view/viewHistory", { code: '',name:'' });
            }
           
        }
    }
</script>