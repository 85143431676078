import _ from 'lodash'
import crypto from '@/mylib/crypto'
// initial state
const state = () => ({
    layoutClass: '',    
    isCheckedLogin: false,
    redirectCheckLogin:'',//redirect after check login
    Modal: {
        isShow: false,
        //for confirm modal
        isConfirm: false,
        triggerConfirm: false,
        callback: '',
        callbackAfterClose: '',
        //common modal props
        title: '',
        content: '',
        type: '',        
        size: 'sm',
        viewHistory: {code:'',name:''}
    },
    Toast:[],
    loading: false,
    loadingBounce: _.debounce(function (commit, loading) {
        commit('setLoading', loading);
 }, 100),
    
})

// getters
const getters = {}

// actions test
const actions = {
    setRedirectCheckLogin({ commit }, routePath) {
        commit('setRedirectCheckLogin', routePath)
    },
    setCheckLogin({ commit }, isChecked) {
        commit('setCheckLogin', isChecked)
    },
    setLayoutClass({ commit },className) {
        commit('setLayoutClass', className)
    },   
    setLoading({ commit }, loading) {
        //commit('setLoading', loading);        
        this.state.view.loadingBounce(commit, loading);
    },
    viewHistory({ commit }, payload) {        
        commit('viewHistory', payload)
    },
    showModal({ commit }, payload) {        
        commit('showModal', payload)
    },
    showToast({ commit }, payload) {
        
        commit('showToast', payload)
    },
    removeToast({ commit },index) {
        commit('removeToast',index)
    },
    emptyToast({ commit }) {
        commit('emptyToast')
    },
}


// mutations
const mutations = {
    setRedirectCheckLogin(state, routePath) {
        state.redirectCheckLogin = routePath;
    },
    setLoading(state, loading) {
        state.loading= loading;
    },
    setCheckLogin(state, isChecked) {
        state.isCheckedLogin = isChecked;
    },
    setLayoutClass(state, className) {
        state.layoutClass = className;
    },
    viewHistory(state, payload) {
        state.Modal.viewHistory = payload;        
    },
    showModal(state, payload) {
        state.Modal.isShow = payload.isShow;
        state.Modal.isConfirm = payload.isConfirm;
        state.Modal.callbackAfterClose = payload.callbackAfterClose;
        if (payload.triggerConfirm) {
            if (state.Modal.callback) state.Modal.callback();
        } else {
            state.Modal.callback = payload.callback;
        }

        state.Modal.title = payload.title;
        state.Modal.content = payload.content;
        state.Modal.type = payload.type;
        state.Modal.size = payload.size;
    },
    showToast(state, payload) {
        const toast = {};
        toast.key = crypto.makeid(10);
        toast.title = payload.title||'';
        toast.content = payload.content || '';
        toast.type = payload.type || '';
        toast.fullWidth = payload.fullWidth || '';
        state.Toast.push(toast);
        
    },
    removeToast(state, index) {
        _.remove(state.Toast, function (n) {
            return n.key == index;
        });
    },
    emptyToast(state) {
        state.Toast.splice(0, state.Toast.length);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
