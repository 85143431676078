
/**
 * Mocking 
 */
//const _products = [
//    { 'id': 1, 'title': 'iPad 4 Mini', 'price': 500.01, 'inventory': 2 },
//    { 'id': 2, 'title': 'H&M T-Shirt White', 'price': 10.99, 'inventory': 10 },
//    { 'id': 3, 'title': 'Charli XCX - Sucker CD', 'price': 19.99, 'inventory': 5 }
//]

export default {
    test(t) {
        console.log(t);
    },
    wait(ms) {
        return new Promise(resolve => {
            setTimeout(resolve, ms)
        })
    },
    debouncer(fn, delay) {
        var timeoutID = null
        return function () {
            clearTimeout(timeoutID)
            var args = arguments
            var that = this
            timeoutID = setTimeout(function () {
                fn.apply(that, args)
            }, delay)
        }
    },
    IsNumeric(event, arg, isDecimal) {

        if (isDecimal != "Y") {
            if (!(event.which >= 48 && event.which <= 57)) {
                return false;
            }
        }
        else {

            if (!(event.which >= 48 && event.which <= 57 || event.which == 46)) {
                return false;
            }
        }
    },
    IsCharacter(event) {

        if (!((event.which >= 65 && event.which <= 95) || (event.which >= 97 && event.which <= 122) || event.which == 32)) {
            return false;
        }
    },
    getMeta(url, callback) {
        const img = new Image();
        img.src = url;
        img.onload = function () { callback(this.width, this.height); }
    },
    formatDate(d, notime) {
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            min = '' + d.getMinutes(),
            sec = '' + d.getSeconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hour.length < 2)
            hour = '0' + hour;
        if (min.length < 2)
            min = '0' + min;
        if (notime) return [year, month, day].join('-');
        return [year, month, day].join('-') + ' ' + [hour, min, sec].join(':');
    },
    formatDate2(d, notime) {//format dd/mm/yyyy
        var month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            min = '' + d.getMinutes(),
            sec = '' + d.getSeconds();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hour.length < 2)
            hour = '0' + hour;
        if (min.length < 2)
            min = '0' + min;
        if (notime) return [day, month, year].join('/');
        return [day,month,year].join('/') + ' ' + [hour, min, sec].join(':');
    },
    formatNumber(n, currencySymbol) {
        //convert to string
        n = n + "";
        // format number 1000000 to 1,234,567
        currencySymbol = currencySymbol || "";
        return currencySymbol + n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatMoney(n, currencySymbol, decCount) {
        //convert to string    
        let sign = '';
        decCount = decCount || 0;
        n = (n + "");
        if (n.substr(0, 1) == '-') sign = '-';
        n = (n + "").replace(/[^0-9.]/g, "");
        let nDec = Math.round((n + 0) % 1 * Math.pow(10, decCount));
        let nInt = Math.floor(n);
        //if Dec reach 100 then increase nInt part
        if (nDec == Math.pow(10, decCount)) {
            nDec = 0;
            nInt += 1;
        }
        nInt = nInt + "";
        //add 000 string to nDec part
        let nDecStr = nDec + "";
        if (decCount == 0) nDecStr = "";
        else if (nDecStr.length < decCount) {
            const len = nDecStr.length;
            for (let i = 0; i < decCount - len; i++) {
                nDecStr = '0' + nDecStr;
            }
        }
        // format number 1000000 to 1,234,567
        currencySymbol = currencySymbol || "";
        return sign + currencySymbol + nInt.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (nDecStr == '' ? '' : '.' + nDecStr);
    },
    noNumbers(e) {
        var charCode = e.which ? e.which :
            (e.charCode ? e.charCode :
                (e.keyCode ? e.keyCode : 0));

        if ((charCode < this.keycodes.number1 || charCode > this.keycodes.number9) &&
            charCode !== this.keycodes.backspace &&
            charCode !== this.keycodes.leftArrow &&
            charCode !== this.keycodes.rightArrow)
            e.preventDefault();
    },
    noNumbersWithDot(e) {
        var charCode = e.which ? e.which :
            (e.charCode ? e.charCode :
                (e.keyCode ? e.keyCode : 0));

        if ((charCode < this.keycodes.number1 || charCode > this.keycodes.number9) &&
            charCode !== this.keycodes.delete &&
            charCode !== this.keycodes.backspace &&
            charCode !== this.keycodes.leftArrow &&
            charCode !== this.keycodes.rightArrow)
            e.preventDefault();
    },
    SaveFile(bytesBase64, mimeType, fileName) {
        var fileUrl = "data:" + mimeType + ";base64," + bytesBase64;
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                var link = window.document.createElement("a");
                link.href = window.URL.createObjectURL(blob, { type: mimeType });
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });

    }
}
