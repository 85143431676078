<template>
    <div id="nb-global-spinner" class="spinner" v-show="show">
        <div class="blob blob-0"></div>
        <div class="blob blob-1"></div>
        <div class="blob blob-2"></div>
        <div class="blob blob-3"></div>
        <div class="blob blob-4"></div>
        <div class="blob blob-5"></div>
    </div>
</template>

<script>
    export default {
        name: 'Spinner',
        
        props: {
            show: Boolean
        }
    }
</script>