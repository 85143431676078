// @ is an alias to /src
import crypto from '@/mylib/crypto'
// initial state
const state = () => ({
    fname: '',
    lname: '',
    username: '',
    userid:0,
    token: '',
    callingApi: [],
    acl: [],
    timeOffset: 0,
    timeZone: '',
})

// getters
const getters = {}

// actions
const actions = {
    checkAcl(e, routeAcl) {        
        return this.state.session.acl.includes(routeAcl);
    },
    async login(e, payload) {        
        const rs = this.dispatch('api/call', { Action: 'User/Login', Object: payload })
        return rs;
    },
    async loginSession(e, payload) {
        const rs = this.dispatch('api/call', { Action: 'User/LoginSession', Object: payload })
        return rs;
    },
    async initCheckAlive({ commit }, payload) {
        commit('initCheckAlive', { commit: commit, dispatch: this.dispatch, router: payload.router });
    },
    //async checkAlive(e, payload) {
        
    //    if (this.state.session.token == "" || this.state.session.token == undefined) {
    //        clearInterval(this.checkAliveInterval);
    //        return;
    //    }
    //    const rs = this.dispatch('api/call', { Action: 'User/KeepAlive', Object: payload })
    //    if (rs.Error != "" && rs.Data == "notlogin") {
    //        //clear session and logout
    //        //clear session and redirect to login
    //        this.state.session.actions.setSession({});
    //        this.$router.push('login');
    //    }
    //},
    async logout() {
        const rs = this.dispatch('api/call', { Action: 'User/Logout'})
        return rs;
    },
    setSession({ commit }, payload) {
        commit('setSession', payload);
    },
    setCallingApi({ commit }, payload) {
        commit('setCallingApi', payload);
    },
    removeCallingApi({ commit }, payload) {
        commit('removeCallingApi', payload);
    },
}

// mutations
const mutations = {
    removeCallingApi(state, payload) {        
        const index = state.callingApi.indexOf(payload);
        if (index > -1) {
            state.callingApi.splice(index, 1); // 2nd parameter means remove one item only
        }
    },
    setCallingApi(state, payload) {
        state.callingApi.push(payload);
    },
    setSession(state, payload) {
        state.lname = payload.LName;
        state.fname = payload.FName;
        state.username = payload.UserName;
        state.userid = payload.UserId;
        state.timeOffset = new Date().getTimezoneOffset();
        state.timeZone = payload.TimeZone;
        state.acl = payload.Acl;
        state.token = payload.Token;
        localStorage.setItem('sex', crypto.Encode3(state.token, process.env.VUE_APP_APIKEY) );
    },
    initCheckAlive(state, payload) {        
        setInterval(async function () {                
            if (state.token == "" || state.token == undefined) {                
                return;
            }
            if (state.callingApi.length > 0) return;
            const rs = await payload.dispatch('api/call', { Action: 'User/KeepAlive' })
            if (rs.Error != "" && rs.Data == "notlogin") {
                //clear session and logout
                //clear session and redirect to login
                payload.commit('setSession', {});
                //payload.router.push('login');
                document.location.reload();
            }
        }, 5000)
        
    }
    //decrementProductInventory(state, { id }) {
    //    const product = state.all.find(product => product.id === id)
    //    product.inventory--
    //}
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
