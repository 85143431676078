<template>

    <aside class="al-sidebar" v-show="showSidebar">
        <perfect-scrollbar>
            <ul class="al-sidebar-list">
                <li class="al-sidebar-list-item" :class="routeName=='Home'?'selected':''">
                    <router-link to="/" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-android-home"></i>
                        <span class="ng-binding">Dashboard</span>
                    </router-link>
                </li>

                <li class="al-sidebar-list-item" :class="routeName=='PeakMonth'?'selected':''"
                    v-show="checkAcl('PeakMonth/List')||checkAcl('PeakMonth/Edit')">
                    <router-link to="/peakmonth" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-android-apps"></i>
                        <span class="ng-binding">PeakMonth</span>
                    </router-link>
                </li>
                <li class="al-sidebar-list-item" :class="routeName=='GroupSale'?'selected':''"
                    v-show="checkAcl('GroupSale/List')">
                    <router-link to="/GroupSale" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-android-contacts"></i>
                        <span class="ng-binding">GroupSale</span>
                    </router-link>
                </li>
                <li class="al-sidebar-list-item" :class="routeName=='Product'?'selected':''"
                    v-show="checkAcl('Product/View')">
                    <router-link to="/product" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-cube"></i>
                        <span class="ng-binding">Product</span>
                    </router-link>
                </li>
                <li class="al-sidebar-list-item" :class="routeName=='Nienvu'?'selected':''"
                    v-show="checkAcl('Nienvu/View')">
                    <router-link to="/Nienvu" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-ios-list-outline"></i>
                        <span class="ng-binding">Niên Vụ</span>
                    </router-link>
                </li>
                <li class="al-sidebar-list-item" :class="routeName=='Zones'?'selected':''"
                    v-show="checkAcl('Zones/Edit')">
                    <router-link to="/Zones" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-ios-location"></i>
                        <span class="ng-binding">Sale Zones</span>
                    </router-link>
                </li>
                <li class="al-sidebar-list-item" :class="routeName=='Revenue'?'selected':''"
                    v-show="checkAcl('Revenue/Upload')">
                    <router-link to="/Revenue" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-briefcase"></i>
                        <span class="ng-binding">Revenue</span>
                    </router-link>
                </li>
                <li class="al-sidebar-list-item" :class="routeName=='ImportOrder'?'selected':''"
                    v-show="checkAcl('ImportOrder/List')||checkAcl('ImportOrder/ExportExcel')">
                    <router-link to="/importorder" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-android-clipboard"></i>
                        <span class="ng-binding">Import Order</span>
                    </router-link>
                </li>

                <li class="al-sidebar-list-item" :class="routeName=='Inventory'?'selected':''"
                    v-show="checkAcl('Inventory/Upload')||checkAcl('Inventory/Download')||checkAcl('Inventory/ViewAll')">
                    <router-link to="/inventory" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-android-upload"></i>
                        <span class="ng-binding">File Inventory</span>
                    </router-link>
                </li>
                <li class="al-sidebar-list-item" :class="routeName=='SalesForecast'?'selected':''"
                    v-show="checkAcl('SalesForecast/View')">
                    <router-link to="/SalesForecast" :class="listLinkClass" @click="clickRouterMenu">
                        <i class="ion-arrow-graph-up-right"></i>
                        <span class="ng-binding">Sale Forecast</span>
                    </router-link>
                </li>
                <li class="al-sidebar-list-item with-sub-menu"
                    :class="{selectedsub:routeName=='ResourceBangGia'||routeName=='ResourceCatalogRuou'||routeName=='ResourceCatalogSake'||routeName=='ResourceHosonangluc'||routeName=='ResourceTraining','ba-sidebar-item-expanded':expandedSubmenu.includes('Resource')}"
                    v-show="checkAcl('Resource/BangGiaDownload')||checkAcl('Resource/BangGiaUpload')||checkAcl('Resource/CatalogRuouDownload')||checkAcl('Resource/CatalogRuouUpload')">
                    <a :class="listLinkClass" tabindex="1" @click="toggleSubMenu('Resource')">
                        <i class="ion-document-text"></i>
                        <span class="ng-binding">Resources</span>
                        <b class="fa fa-angle-down ng-scope"></b>
                    </a>
                    <ul class="al-sidebar-sublist" :class="slideClass">
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='CurrentPromotion'?'selected':''">
                            <router-link to="/CurrentPromotion" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-android-map"></i>
                                <span class="ng-binding">Current Promotion</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ResourceBangGia'?'selected':''" v-show="checkAcl('Resource/BangGiaDownload')||checkAcl('Resource/BangGiaUpload')">
                            <router-link to="/Resource/BangGia" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-social-usd"></i>
                                <span class="ng-binding">All Prices (Bảng Giá)</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ResourceCatalogRuou'?'selected':''" v-show="checkAcl('Resource/CatalogRuouDownload')||checkAcl('Resource/CatalogRuouUpload')">
                            <router-link to="/Resource/CatalogRuou" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-ios-pint"></i>
                                <span class="ng-binding">Catalog Wine</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ResourceCatalogSake'?'selected':''" v-show="checkAcl('Resource/CatalogSakeDownload')||checkAcl('Resource/CatalogSakeUpload')">
                            <router-link to="/Resource/CatalogSake" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-ios-pint"></i>
                                <span class="ng-binding">Catalog Sake</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ResourceHosonangluc'?'selected':''" v-show="checkAcl('Resource/HosonanglucDownload')||checkAcl('Resource/HosonanglucUpload')">
                            <router-link to="/Resource/Hosonangluc" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-ios-pricetag"></i>
                                <span class="ng-binding">Presentation (Hồ sơ năng lực)</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ResourceTraining'?'selected':''" v-show="checkAcl('Resource/TrainingDownload')||checkAcl('Resource/TrainingUpload')">
                            <router-link to="/Resource/Training" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-android-walk"></i>
                                <span class="ng-binding">Training</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ResourceTastingNote'?'selected':''" v-show="checkAcl('Resource/TastingNoteDownload')||checkAcl('Resource/TastingNoteUpload')">
                            <router-link to="/Resource/TastingNote" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-ios-wineglass"></i>
                                <span class="ng-binding">Tasting Note</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ResourceMarketingEvent'?'selected':''" v-show="checkAcl('Resource/MarketingEventDownload')||checkAcl('Resource/MarketingEventUpload')">
                            <router-link to="/Resource/MarketingEvent" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-ios-people"></i>
                                <span class="ng-binding">Marketing Event</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='SupplierOrderTracking'?'selected':''">
                            <router-link to="/SupplierOrderTracking" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-android-map"></i>
                                <span class="ng-binding">Supplier Order Tracking</span>
                            </router-link>
                        </li>

                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='DetailedImportOrder'?'selected':''">
                            <router-link to="/DetailedImportOrder" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-android-exit"></i>
                                <span class="ng-binding">Detailed Import Order</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="al-sidebar-list-item with-sub-menu"
                    :class="{selectedsub:routeName=='EnterFeedback'||routeName=='ViewFeedback','ba-sidebar-item-expanded':expandedSubmenu.includes('Feedback')}"
                    v-show="checkAcl('Feedback/View')||checkAcl('Feedback/Enter')">
                    <a :class="listLinkClass" tabindex="1" @click="toggleSubMenu('Feedback')">
                        <i class="ion-android-list"></i>
                        <span class="ng-binding">Feedback</span>
                        <b class="fa fa-angle-down ng-scope"></b>
                    </a>
                    <ul class="al-sidebar-sublist" :class="slideClass">
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ViewFeedback'?'selected':''">
                            <router-link to="/ViewFeedback" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-android-menu"></i>
                                <span class="ng-binding">View Feedback</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='EnterFeedback'?'selected':''">
                            <router-link to="/EnterFeedback" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-edit"></i>
                                <span class="ng-binding">Enter Feedback</span>
                            </router-link>
                        </li>
                       
                    </ul>
                </li>
                <li class="al-sidebar-list-item with-sub-menu"
                    :class="{selectedsub:routeName=='ProductDetail'||routeName=='HamperBox'||routeName=='Hamper'||routeName=='HamperTracking'||routeName=='ProductType','ba-sidebar-item-expanded':expandedSubmenu.includes('Hamper')}"
                    v-show="checkAcl('Resource/HamperPrice2024Download')||checkAcl('Resource/HamperPrice2024Upload')||checkAcl('ProductDetail/View')||checkAcl('ProductType/View')||checkAcl('HamperItem/View')||checkAcl('HamperBox/View')||checkAcl('Hamper/View')||checkAcl('Hamper/Tracking')">
                    <a :class="listLinkClass" tabindex="1" @click="toggleSubMenu('Hamper')">
                        <i class="ion-ribbon-b"></i>
                        <span class="ng-binding">Hamper 2024</span>
                        <b class="fa fa-angle-down ng-scope"></b>
                    </a>
                    <ul class="al-sidebar-sublist" :class="slideClass">
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ResourceCatalogRuou'?'selected':''" v-show="checkAcl('Resource/HamperPrice2024Download')||checkAcl('Resource/HamperPrice2024Upload')">
                            <router-link to="/Resource/HamperPrice2024" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-ios-pint"></i>
                                <span class="ng-binding">HamperPrice 2024</span>
                            </router-link>
                        </li>

                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ProductDetail'?'selected':''" v-show="checkAcl('ProductDetail/View')">
                            <router-link to="/ProductDetail" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-cube"></i>
                                <span class="ng-binding">Wine/Sake/Juice</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='ProductType'?'selected':''" v-show="checkAcl('ProductType/View')">
                            <router-link to="/ProductType" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-cube"></i>
                                <span class="ng-binding">Product Type</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='HamperItem'?'selected':''" v-show="checkAcl('HamperItem/View')">
                            <router-link to="/HamperItem" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-bowtie"></i>
                                <span class="ng-binding">Hamper's Item</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='HamperBox'?'selected':''" v-show="checkAcl('HamperBox/View')">
                            <router-link to="/HamperBox" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-social-dropbox-outline"></i>
                                <span class="ng-binding">Box and Bag</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='Hamper'?'selected':''" v-show="checkAcl('Hamper/View')">
                            <router-link to="/Hamper" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-ribbon-b"></i>
                                <span class="ng-binding">Hampers</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='HamperTracking'?'selected':''" v-show="checkAcl('Hamper/Tracking')">
                            <router-link to="/HamperTracking" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-android-map"></i>
                                <span class="ng-binding">Hamper Tracking</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="al-sidebar-list-item with-sub-menu"
                    :class="{selectedsub:routeName=='User'||routeName=='UserGroup','ba-sidebar-item-expanded':expandedSubmenu.includes('User')}"
                    v-show="checkAcl('User/List')||checkAcl('UserGroup/List')">
                    <a :class="listLinkClass" tabindex="1" @click="toggleSubMenu('User')">
                        <i class="ion-android-person"></i>
                        <span class="ng-binding">Manage User</span>
                        <b class="fa fa-angle-down ng-scope"></b>
                    </a>
                    <ul class="al-sidebar-sublist" :class="slideClass">
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='UserGroup'?'selected':''" v-show="checkAcl('UserGroup/List')">
                            <router-link to="/usergroup" :class="listLinkClass" @click="clickRouterMenu">
                                <span class="ng-binding">Group</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='User'?'selected':''" v-show="checkAcl('User/List')">
                            <router-link to="/user" :class="listLinkClass" @click="clickRouterMenu">
                                <span class="ng-binding">User</span>
                            </router-link>
                        </li>
                        <li class="ba-sidebar-sublist-item ng-scope" :class="routeName=='Config'?'selected':''" v-show="checkAcl('Config/Read')">
                            <router-link to="/config" :class="listLinkClass" @click="clickRouterMenu">
                                <i class="ion-android-settings"></i>
                                <span class="ng-binding">Config</span>
                            </router-link>
                        </li>
                    </ul>

                </li>




            </ul>

        </perfect-scrollbar>
        <div class="sidebar-hover-elem show-hover-elem" style="height: 42px;"></div>
    </aside>

</template>
<style>
    .my-slide {
        overflow: hidden !important;
        display: block !important;
        height: 0px;
        transition: height 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    }
</style>
<script>
    import $ from 'jquery'
    import _ from 'lodash'
    import { mapState} from 'vuex'
    export default {
        name: 'SideBar',
        props: ['modelValue'],
        emits: ['update:modelValue'],

        data: function () {
            return {
                options: {
                    height: '100%',
                    size: 0
                },
                counter: 0,                
                expandMenuClass: 'ba-sidebar-item-expanded',
                slideClass: 'my-slide',
                listLinkClass: 'al-sidebar-list-link',
                expandedSubmenu: []
            }
        },
        computed: {
            ...mapState({
                showSidebar: state => state.view.layoutClass != 'auth-main',
                layoutClass: state => state.view.layoutClass,
            }),
            isDefaultCollapsed() {
                return this.modelValue;
            },
            routeName() {                
                return this.$route.name;
            },
            routeCategory() {
                console.log(this.$route.meta);
                return this.$route.meta["resource"];
            }
        },
        created() {
            console.log('SideBar created');
        },
        methods: {
            checkAcl(router) {                
                return this.$store.state.session.acl.includes(router);
            },
            clickRouterMenu() {
                //if layout is menu-collapsed default or screen <500 (mobile) then collapsed sidebar after when click on a router link
                if (this.isDefaultCollapsed || window.innerWidth < 500) {
                    this.$store.dispatch('view/setLayoutClass', 'menu-collapsed');
                }
            },
            toggleSubMenu: function (menuname) {                
                //get parent and sible li element
                let parentEl = event.target.parentElement;
                let sibleEl = event.target.nextSibling;
                if (parentEl.tagName != 'LI') {                    
                    sibleEl = parentEl.nextSibling;
                    parentEl = parentEl.parentElement;                    
                }
                
                //if sidebar is collapsed then expand sidebar
                if (this.layoutClass == "menu-collapsed") {
                    this.$store.dispatch('view/setLayoutClass', '');
                    //close submenu if it's already expanse
                    parentEl.classList.remove(this.expandMenuClass);
                    sibleEl.style.height = '0px';                    
                }

                //toggle the submenu
                    //if submenu is current expand then collapsed
                if (parentEl.classList.contains(this.expandMenuClass)) {
                    parentEl.classList.remove(this.expandMenuClass);
                    sibleEl.style.height = "0";
                    //remove expand menuname in array
                    _.remove(this.expandedSubmenu, function (n) {
                        return n == menuname;
                    });

                }
                else {
                    parentEl.classList.add(this.expandMenuClass);
                    sibleEl.style.height = sibleEl.scrollHeight + 'px';
                    //add expand menuname to array for checking if click on another menuitem
                    this.expandedSubmenu.push(menuname);
                }
                
            },
            
        },
        mounted: function () {
            console.log('sidebar mounted');
            const hoverEl = $('.show-hover-elem');
            const pageTopHeight = $('.page-top').height();
            //set height for menubar scroll
            $('.ps').css('height', (window.innerHeight - pageTopHeight) + 'px');

            //menu item hover
            $("." + this.listLinkClass).hover(                
                (e) => {
                    if (window.innerWidth < 1200) return;
                    let linkEl = e.target;
                    if (!linkEl.classList.contains(this.listLinkClass))
                        linkEl = linkEl.parentElement;
                    hoverEl.css('top', ($(linkEl).offset().top - pageTopHeight - document.documentElement.scrollTop) + 'px');
                    hoverEl.css('height', $(linkEl).height() + 'px');
                },
                () => {

                }).click(() => {


                });
            //sidebar menu mouse event
            $(".al-sidebar").hover(
                () => { },
                () => {
                    //hide sidebar el
                    hoverEl.css('top', '-200px');
                    //set collapsed if default is collapsed
                    if (this.isDefaultCollapsed)
                        this.$store.dispatch('view/setLayoutClass', 'menu-collapsed');
                },
            )
            //menu item click
        },
        watch: {          
            menuItemSelected(newVal, oldVal) {
                console.log(newVal, oldVal);
            }

        }


    }
</script>