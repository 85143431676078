import { createStore, createLogger } from 'vuex'
import api from './api'
import session from './session'
import view from './view'
import user from './user'
import resource from './resource'
const debug = false//process.env.NODE_ENV !== 'production'
//https://levelup.gitconnected.com/complete-vuex-4-guide-states-getters-mutations-actions-modules-plugins-and-composition-api-eff469c43b4f
export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        api,
        session,
        view,
        user,
        resource
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
