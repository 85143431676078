
// initial state
const state = () => ({
    groups: [],
    
})

// getters
const getters = {}

// actions test
const actions = {    
    setEditId({ commit }, id) {
        commit('setEditId', id)
    },
    setGroups({ commit }, groups) {
        commit('setGroups', groups)
    },
   
}


// mutations
const mutations = {
    setEditId(state, id) {
        state.editId = id;
    },
    setGroups(state, groups) {
        state.groups = groups;
        state.index = true;
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
