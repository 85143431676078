<template>
    <div id="toast-container" class="toast-top-center" @click="dismiss"
         style="pointer-events: auto;">
        <transition-group name="list" tag="div">
            <div v-for="item in Toast.slice().reverse()" :key="item.key"
                 id="item.key"
                 :class="'toast show toast-'+item.type"
                 :style="fadeout(item.key)"
                 @mouseover.self="isHover=item.key"
                 @mouseleave.self="isHover='';fadeout(item.key)">
                <div>
                    <div class="toast-title">{{item.title}}</div>
                    <div class="toast-message" v-html="item.content"></div>
                </div>
            </div>
        </transition-group>
    </div>

</template>
<style scoped>
    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to { 
        opacity:0!important;
        transform: translateY(30px);
    }

</style>
<script>
    import { mapState, mapActions } from 'vuex'
    import $ from 'jquery'
    export default {
        name: 'Toast',
        data: function () {
            return {
                elId: '#toast-container',
                isHover: '',
            }
        },
        computed: mapState({            
            //title: state => state.view.Toast.title,
            //content: state => state.view.Toast.content,
            Toast: state=>state.view.Toast,
            //fullWidth: state => state.view.Toast.fullWidth,
            //classType: function () {
            //    const type = this.$store.state.view.Toast.type;
            //    if (type == 'warning') return 'toast-warning';
            //    else if (type == 'success') return 'toast-success';
            //    else if (type == 'error') return 'toast-error';
            //    else if (type == 'info') return 'toast-info';
            //}
        }),
        mounted() {
           
        },
        methods: {
            ...mapActions({
                'removeToast': 'view/removeToast'
            }),
            fadeout(key) {
                //console.log(key)
                setTimeout(() => { if (this.isHover != key) this.removeToast(key) }, 3000);
            },
            dismiss() {
                this.$store.dispatch('view/emptyToast');
            }
        },
        watch: {
            Toast() {
                $('.toast').toast({});
            }

        }
    }
</script>