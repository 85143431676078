<template>
    
        <div class="page-top clearfix" v-show="showPageTop">
            <a href="#/dashboard" class="al-logo clearfix">
                <span>Phu</span>Admin
            </a>
            <a href="javascript:;" class="collapse-menu-link ion-navicon" @click="toggleMenu"></a>

            <div class="user-profile clearfix">
                <div class="al-user-profile dropdown">
                    <a id="dropdownMenuProfile" href="javascript:;" data-bs-toggle="dropdown">
                        <img src="@/assets/img/app/profile/user.svg">

                    </a>
                    <ul aria-labelledby="dropdownMenuProfile" class="top-dropdown-menu profile-dropdown dropdown-menu">
                        <li>
                            <a href="javascript:;" >
                                {{username}}
                            </a>
                        </li>
                        <li>
                            <router-link to="/profile" class="signout">
                                <i class="fa fa-user"></i>Profile
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/setting">
                                <i class="fa fa-cog"></i>Settings
                            </router-link>
                        </li>
                        <li>
                            <a href="javascript:;" class="signout" @click="logout">
                                <i class="fa fa-power-off"></i>Sign out
                            </a>
                        </li>
                    </ul>

                </div>
                <!--<msg-center>-->
                    <!--<ul class="al-msg-center clearfix">
                        <li class="dropdown">
                            <a id="msg-toggle-link" href="javascript:;" class="msg dropdown-toggle">
                                <i class="fa fa-bell-o"></i>
                                <span>5</span>
                                <div class="notification-ring"></div>
                            </a>
                            <div uib-dropdown-menu="" class="top-dropdown-menu dropdown-menu">
                                <i class="dropdown-arr"></i>
                                <div class="header clearfix">
                                    <strong>Notifications</strong>
                                    <a href="">Mark All as Read</a>
                                    <a href="">Settings</a>
                                </div>
                                <div class="msg-list">-->
                                    <!-- ngRepeat: msg in notifications -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img   src="assets/img/app/profile/Vlad.png" class="photo-msg-item">
                                        </div>
                                        <div class="msg-area">
                                            <div  class="ng-binding">
                                                <strong>Vlad</strong> posted a new article.
                                            </div>
                                            <span class="ng-binding">1 min ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in notifications -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img   src="assets/img/app/profile/Kostya.png" class="photo-msg-item">
                                        </div>
                                        <div class="msg-area">
                                            <div  class="ng-binding">
                                                <strong>Kostya</strong> changed his contact information.
                                            </div>
                                            <span class="ng-binding">2 hrs ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in notifications -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img   src="assets/img/shopping-cart.svg">
                                        </div>
                                        <div class="msg-area">
                                            <div  class="ng-binding">New orders received.</div>
                                            <span class="ng-binding">5 hrs ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in notifications -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img   src="assets/img/app/profile/Andrey.png" class="photo-msg-item">
                                        </div>
                                        <div class="msg-area">
                                            <div  class="ng-binding">
                                                <strong>Andrey</strong> replied to your comment.
                                            </div>
                                            <span class="ng-binding">1 day ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in notifications -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img   src="assets/img/app/profile/Nasta.png" class="photo-msg-item">
                                        </div>
                                        <div class="msg-area">
                                            <div  class="ng-binding">Today is <strong>Nasta</strong>'s birthday. </div>
                                            <span class="ng-binding">2 days ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in notifications -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img   src="assets/img/comments.svg">
                                        </div>
                                        <div class="msg-area">
                                            <div  class="ng-binding">New comments on your post.</div>
                                            <span class="ng-binding">3 days ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in notifications -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img   src="assets/img/app/profile/Kostya.png" class="photo-msg-item">
                                        </div>
                                        <div class="msg-area">
                                            <div  class="ng-binding">
                                                <strong>Kostya</strong> invited you to join the event.
                                            </div>
                                            <span class="ng-binding">1 week ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in notifications -->
                                <!--</div>
                                <a href="">See all notifications</a>
                            </div>
                        </li>
                        <li uib-dropdown="" class="dropdown">
                            <a id="notify-toggle-link" href="javascript:;"  class="dropdown-toggle">
                                <i class="fa fa-envelope-o"></i>
                                <span>5</span>
                                <div class="notification-ring"></div>
                            </a>
                            <div uib-dropdown-menu="" class="top-dropdown-menu dropdown-menu">
                                <i class="dropdown-arr"></i>
                                <div class="header clearfix">
                                    <strong>Messages</strong>
                                    <a href="">Mark All as Read</a>
                                    <a href="">Settings</a>
                                </div>
                                <div class="msg-list">-->
                                    <!-- ngRepeat: msg in messages -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img class="photo-msg-item"  src="@/assets/img/app/profile/Nasta.png">
                                        </div>
                                        <div class="msg-area">
                                            <div class="ng-binding">After you get up and running, you can place Font Awesome icons just about...</div>
                                            <span class="ng-binding">1 min ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in messages -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img class="photo-msg-item"  src="@/assets/img/app/profile/Vlad.png">
                                        </div>
                                        <div class="msg-area">
                                            <div class="ng-binding">You asked, Font Awesome delivers with 40 shiny new icons in version 4.2.</div>
                                            <span class="ng-binding">2 hrs ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in messages -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img class="photo-msg-item"  src="@/assets/img/app/profile/Kostya.png">
                                        </div>
                                        <div class="msg-area">
                                            <div class="ng-binding">Want to request new icons? Here's how. Need vectors or want to use on the...</div>
                                            <span class="ng-binding">10 hrs ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in messages -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img class="photo-msg-item"  src="@/assets/img/app/profile/Andrey.png">
                                        </div>
                                        <div class="msg-area">
                                            <div class="ng-binding">Explore your passions and discover new ones by getting involved. Stretch your...</div>
                                            <span class="ng-binding">1 day ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in messages -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img class="photo-msg-item"  src="@/assets/img/app/profile/Nasta.png">
                                        </div>
                                        <div class="msg-area">
                                            <div class="ng-binding">Get to know who we are - from the inside out. From our history and culture, to the...</div>
                                            <span class="ng-binding">1 day ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in messages -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img class="photo-msg-item"  src="@/assets/img/app/profile/Kostya.png">
                                        </div>
                                        <div class="msg-area">
                                            <div class="ng-binding">Need some support to reach your goals? Apply for scholarships across a variety of...</div>
                                            <span class="ng-binding">2 days ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in messages -->
                                    <!--<a href="" class="clearfix" >
                                        <div class="img-area">
                                            <img class="photo-msg-item"  src="@/assets/img/app/profile/Vlad.png">
                                        </div>
                                        <div class="msg-area">
                                            <div class="ng-binding">Wrap the dropdown's trigger and the dropdown menu within .dropdown, or...</div>
                                            <span class="ng-binding">1 week ago</span>
                                        </div>
                                    </a>-->
                                    <!-- end ngRepeat: msg in messages -->
                                <!--</div>
                                <a href="">See all messages</a>
                            </div>
                        </li>
                    </ul>-->
                <!--</msg-center>-->
            </div>
        </div>
    
</template>
<script>
    import { mapState } from 'vuex'
    
    export default {
        name: 'PageTop',
        props: ['modelValue'],
        emits: ['update:modelValue'],
        data: function () {
            return {
                profileDropdown: false
            }
        },
        computed: mapState({
            showPageTop: state => state.view.layoutClass != 'auth-main',
            layoutClass: state => state.view.layoutClass,
            username: state => state.session.username,
        }),
        created() {
            console.log('PageTop created');
        },
        mounted() {
            console.log('PageTop mount');
        },
        methods: {
         
            toggleMenu: function () {
                //if collapsed
                if (this.layoutClass == "menu-collapsed") {
                    this.$store.dispatch('view/setLayoutClass', '');
                    this.$emit('update:modelValue', false);
                }
                else {
                    this.$store.dispatch('view/setLayoutClass', 'menu-collapsed');
                    this.$emit('update:modelValue', true);
                }
                
            },
            logout: async function () {
                this.$store.dispatch('view/setLoading', true);
                const rs = await this.$store.dispatch('session/logout');

                var islogout = true;
                if (rs.Error != "") {
                    if (rs.Data != "notlogin") {
                        this.$store.dispatch('view/showModal', { isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                        islogout = false;
                    }
                }
                if (islogout) {

                    //clear session and redirect to login
                    this.$store.dispatch('session/setSession', {});
                    document.location.reload();
                } else {
                    this.$store.dispatch('view/setLoading', false);
                }
            }
        },
    }
</script>