<template>
    <div class="al-content">
        <content-top>
            <div class="content-top clearfix">
                <h1 class="al-title ">Dashboard</h1> &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        </content-top>
        <!-- uiView: -->
        <div class="row " v-show="checkAcl('DashboardGroupSales')">
            <div class="col-md-12">
                <a href="https://airtable.com/shrg2J49KMZJBoFee" target="_blank">Click at Add good News</a>
            </div>
            <div class="col-md-12">
                <div class="panel with-scroll horizontal-tabs tabs-panel">
                    <div class="panel-body">
                        <iframe class="airtable-embed" src="https://airtable.com/embed/shrNU5FXnrroaewiD?backgroundColor=purpleLight&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>
                    </div>
                </div>
            </div>
        </div>
        </div>
</template>

<script>
    // @ is an alias to /src

    import c from '@/mylib/common'
    import { mapState, mapActions } from 'vuex'
    export default {
        name: 'Home',
       
         computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
            timeOffset: state => state.session.timeOffset,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {                
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                
               
            },
         },
        
        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
            
           
            
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                if (newVal) {
                    this.start();
                }
            },

        }
    }
</script>
