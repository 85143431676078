import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Modal from '@/components/Modal.vue';
/*import BootstrapVue3 from 'bootstrap-vue-3'*/

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.min.js";
//import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import 'mdb-vue-ui-kit/css/mdb.min.css';


import myplugin from './assets/script/myplugin'
import mylib from './assets/script/mylib'
const app = createApp(App);
app.config.globalProperties.mylib = mylib;
app.component('Modal',Modal);
app
    .use(store)
    .use(router)
    .use(myplugin)
    .use(PerfectScrollbar)
    /*.use(BootstrapVue3)*/
    .mount('body');






