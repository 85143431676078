// initial state
const state = () => ({
    Name: '',
})

// getters
const getters = {}

// actions
const actions = {
   
    setName({ commit }, payload) {
        commit('setName', payload);
    },
}

// mutations
const mutations = {   
    setSession(state, payload) {
        state.Name = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
