// @ is an alias to /src

import axios from 'axios';
import crypto from '@/mylib/crypto'


// actions
const actions = {
    async call(e, payload) {
        let result = {Error:''};
        try {
            //if is calling this Action then not call again
            if (this.state.session.callingApi.indexOf(payload.Action) > -1) return;
            this.dispatch('session/setCallingApi', payload.Action);
            const sex = crypto.Encode3(this.state.session.token, process.env.VUE_APP_APIKEY);
            localStorage.setItem('sex', sex);
            const token = this.state.session.token == '' ? process.env.VUE_APP_APIKEY : this.state.session.token            
            const data = crypto.Encode3(JSON.stringify(payload.Object), process.env.VUE_APP_APIKEY);
            var formData = new FormData();            
            formData.append("data", data);
            
            if (payload.Files != undefined) {
                if (Array.isArray(payload.Files)) {
                    for (let i = 0; i < payload.Files.length; i++) {
                        formData.append("Files[]", payload.Files[i]);
                    }
                }
                else {
                    formData.append("Files", payload.Files);
                }
            }
            const requestUrl = crypto.encDat2(payload.Action + "/" + crypto.makeid(5));
            this.strict && console.log("Request Action: " + requestUrl, payload, data);
            
            const rs = await axios.post(process.env.VUE_APP_APIURL + requestUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'key': process.env.VUE_APP_APPNAME + "-" + crypto.EncodeW2(process.env.VUE_APP_APPVERSION + "," + token, process.env.VUE_APP_APIKEY)
                }
            });
            let message = '';
            try {
                
                try {
                    //try to decode response message                                
                    message = crypto.DecodeW3(rs.data, token);
                } catch (err) {
                    //can not decode response
                    
                }
                //try to decode with default key
                if (message == "")                    
                    message = crypto.DecodeW3(rs.data, "");
                try {
                    //try to parse json message into apiresponse object                    
                    result = JSON.parse(message);                    
                } catch (err) {
                    result.Error = 'Can not parse response message.';
                }
            } catch (err) {
                result.Error = 'Can not decode response message.';
            }
        } catch (err) {
            result.Error = err.message;
        }
        this.dispatch('session/removeCallingApi', payload.Action);
        this.strict && console.log("Response Action: " + payload.Action,result);
        return result;
    },
    async callDownload(e, payload) {
        let result = { Error: '' };
        try {
            //if is calling this Action then not call again
            if (this.state.session.callingApi.indexOf(payload.Action) > -1) return;
            this.dispatch('session/setCallingApi', payload.Action);
            const sex = crypto.Encode3(this.state.session.token, process.env.VUE_APP_APIKEY);
            localStorage.setItem('sex', sex);
            const token = this.state.session.token == '' ? process.env.VUE_APP_APIKEY : this.state.session.token
            const data = crypto.Encode3(JSON.stringify(payload.Object), process.env.VUE_APP_APIKEY);
            var formData = new FormData();
            formData.append("data", data);
            if (payload.Files != undefined) {
                formData.append("Files", payload.Files);
            }
            const requestUrl = crypto.encDat2(payload.Action + "/" + crypto.makeid(5));
            this.strict && console.log("Request Action: " + requestUrl, payload, data);
            const rs = await axios.post(process.env.VUE_APP_APIURL + requestUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'key': process.env.VUE_APP_APPNAME + "-" + crypto.EncodeW2(process.env.VUE_APP_APPVERSION + "," + token, process.env.VUE_APP_APIKEY)
                },
                responseType: 'arraybuffer',
                useCredentails: true
            });
            try {
                let message = '';       
                let byteArray = new Uint8Array(rs.data);
                var blob = new Blob([byteArray], { type: "text/plain" });
                try {
                    //try to decode normal response message        
                    //https://programmingwithswift.com/how-to-convert-byte-array-to-string-with-javascript/
                    message = crypto.DecodeW3(String.fromCharCode(...byteArray), token);
                    if (message != "") {
                        try {
                            //try to parse json message into apiresponse object                    
                            result = JSON.parse(message);
                        } catch (err) {
                            result.Error = 'Can not parse response message.';
                        }
                    }
                } catch (err) {
                    var url = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="

                    fetch(url)
                        .then(res => res.blob())
                        .then((blobcontent) => {
                            console.log(blobcontent);
                            //var blob = new Blob([byteArray], { type: "text/plain" });
                        var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                        link.download = payload.Object.fileName;
                        link.click();
                        })


                    //var blob = new Blob([byteArray], { type: "text/plain" });
                    //    var link = document.createElement('a');
                    //    link.href = window.URL.createObjectURL(blob);
                    //    link.download = payload.Object.fileName;
                    //    link.click();

                    
                }

                
            } catch (err) {
                console.log(err);
                result.Error = 'Can not decode response message.';
            }
        } catch (err) {
            result.Error = err.message;
        }
        this.dispatch('session/removeCallingApi', payload.Action);
        this.strict && console.log("Response Action: " + payload.Action, result);
        return result;
    }
}

export default {
    namespaced: true,
    
    actions,
    
}