import MD5 from "crypto-js/md5";

export default {
	makeid(num) {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for (var i = 0; i < num; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	},
	base64encode(input) {
		return btoa(unescape(encodeURIComponent(input))).replace(/=/g, "");
	},
	base64decode(input) {
		if (input == undefined || input == null || input.trim() == "") return "";
		return decodeURIComponent(escape(atob(input)));
	},
	md5(input) {
		return MD5(input);
	},
	DecodeW3(data, keysalt) {
		if (data == undefined || data.trim() == "") return "";
		//data = CompressToBase64(data)
		if (keysalt.length < 2) {
			keysalt = this.base64encode("<<xyz>>");
		}
		let debug = false;
		let x = keysalt.length;
		debug && console.log(keysalt);

		data = data.substr(0, data.length - 5);
		debug && console.log(data);

		keysalt = this.base64encode("<<") + this.base64encode(keysalt) + this.base64encode(">>");
		data = data.substr(x);
		debug && console.log(data);

		data = data.replace(keysalt, "");
		debug && console.log(data);

		data = this.base64decode(data);
		debug && console.log(data);
		return data;
	},
	EncodeW2(data, keysalt) {
		if (data == undefined || data.trim() == "") return "";
		if (keysalt.length < 2) {
			keysalt = "<<xyz>>";
		}		
		keysalt = "<<" + keysalt + ">>";
		let debug = false;
		debug && console.log("Encode DATA: ", data, keysalt);
		try {
			//1. base64
			data = this.base64encode(data);
			debug && console.log(data);
			keysalt = this.base64encode(keysalt);
			//2. random string			
			let x = this.makeid(keysalt.length);
			let l = x.length;
			if (x.length > data.length) l = data.length;
			data = data.substr(0, l) + x + data.substr(l);
			debug && console.log(data);
			//3. add keysalt to random position
			let x2 = Math.floor(Math.random() * data.length); //random 0=> datalen
			data = data.substr(0, x2) + keysalt + data.substr(x2);
			return data;
		}
		catch (e) {
			console.log(e);
			return "";
		}
	},
	Encode3(data, keysalt) {
		if (data == undefined || data.trim() == "") return "";
		if (keysalt.length < 2) {
			keysalt = "<<xyz>>";
		}
		let x = keysalt.length;
		keysalt = this.base64encode("<<") + keysalt + this.base64encode(">>");
		let debug = false;
		debug && console.log("Encode DATA: ", data, keysalt);
		try {
			//1. base64
			data = this.base64encode(data);
			debug && console.log(data);
			//2. encodeW2			
			let x2 = Math.floor(Math.random() * data.length) + 1;
			data = data.substr(0, x2) + keysalt + data.substr(x2);
			debug && console.log(data);
			//3. Add random string
			data += this.makeid(keysalt.length);
			//4. get hash
			let hash = MD5(data);
			//5. Shuffle
			//random shuffle time (2 -> 9)
			let div = Math.floor(Math.random() * 8) + 2;
			data = this.Shuffle(data, div);
			debug && console.log(data);
			//6. add base64 n shuffletime
			data += this.base64encode(div);
			debug && console.log(data);
			//7. add hash string
			data = hash + data;
			debug && console.log(data);
			//8. shuffle x
			data = this.Shuffle(data, x);
			debug && console.log(data);
			return data;
		}
		catch (e) {
			console.log(e);
			return "";
		}
	},
	Shuffle(data, div) {
		if (div >= data.length) return data;
		let result = [];
		let irs1 = 0;
		let divlen = Math.floor(data.length / div);
		let irs2 = divlen;
		for (let i = 0; i < data.length; i++) {
			if ((i + 1) % div == 0) {
				result[irs1] = data[i]; // string([]rune(data)[i])
				irs1++;
			}
			else {
				result[irs2] = data[i];
				irs2++;
			}
		}
		return result.join("");
	},
	encDat2(data, oddnumber) {
		if (data == undefined || data.trim() == "") return "";
		if (oddnumber == undefined) oddnumber = 5;
		oddnumber = 10;
		var l = Math.floor((Math.random() * data.length) + 1);//random from 1 to data len
		var x = this.makeid(oddnumber);
		// mylog('random x string:'+x);
		var y = this.base64encode(x);
		y = y.replace(/=/g, "");
		// mylog('yb64:'+y);
		data = data.substring(0, l) + y + data.substring(l);
		// mylog('datay:'+data);
		//data=LZString["compressToBase64"](data);    
		data = this.base64encode(data);
		//mylog("lzjs: "+detailurl);
		//data=data.replace(/===/g,"A==");
		data = data.replace(/=/g, "");
		// mylog('data compress:'+data);
		// mylog('data final:'+x+data);   
		return x + data
	},
	//decDat(data, div) {
	//	var key = data;
	//	if (key == "") return '';
	//	if (div == undefined) div = 8;
	//	var l = Math.floor((key.length - 2) / div);
	//	var num = key.substr(l, 2);
	//	key = key.substr(0, l) + key.substr(l + 2);

	//	//console.log("num: "+num);
	//	if (div > 0) {
	//		//print_r($num);print_r("\r\n");	
	//		//get odd string

	//		var oddstr = key.substr(0, Math.ceil(key.length / div));
	//		var ukey = key.replace(oddstr, '');
	//		var base64 = '';

	//		for (var i = oddstr.length - 1; i >= 0; i--) {
	//			base64 += oddstr.substr(oddstr.length - 1);
	//			oddstr = oddstr.substr(0, oddstr.length - 1);

	//			if (ukey.length - div + 1 > 0)
	//				base64 += (ukey.substr(ukey.length - div + 1)).split("").reverse().join("");
	//			else
	//				base64 += ukey.split("").reverse().join("");
	//			if (i > 0)
	//				ukey = ukey.substr(0, ukey.length - div + 1);
	//		}
	//		base64 = base64.substr(0, base64.length - num);
	//		data = this.base64decode(base64);

	//		return data;
	//	}
	//	return '';
	//},
	lzw_decode(s) {
		var dict = {};
		var data = (s + "").split("");
		var currChar = data[0];
		var oldPhrase = currChar;
		var out = [currChar];
		var code = 256;
		var phrase;
		for (var i = 1; i < data.length; i++) {
			var currCode = data[i].charCodeAt(0);
			if (currCode < 256) {
				phrase = data[i];
			}
			else {
				phrase = dict['_' + currCode] ? dict['_' + currCode] : (oldPhrase + currChar);
			}
			out.push(phrase);
			currChar = phrase.charAt(0);
			dict['_' + code] = oldPhrase + currChar;
			code++;
			oldPhrase = phrase;
		}
		return out.join("");
	}


}
