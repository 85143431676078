import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    //user controller
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/User/Login.vue')
    },
    {
        path: '/user',
        name: 'UserIndex',
        meta: { acl: [ 'User/List' ],},
        component: () => import('@/views/User/Index.vue')
    },
    {
        path: '/useradd',
        name: 'UserAdd',
        meta: { acl: [ 'User/Add' ],},
        component: () => import('@/views/User/Edit.vue')
    },
    {
        path: '/user/edit/:id',
        name: 'UserEdit',
        meta: { acl: [ 'User/Edit' ],},
        component: () => import('@/views/User/Edit.vue')
    },
    {
        path: '/setting',
        name: 'UserSetting',
        meta: { acl: [ 'User/Setting' ],},
        component: () => import('@/views/User/Setting.vue')
    },
    {
        path: '/profile',
        name: 'UserProfile',
        meta: { acl: [ 'User/Profile' ],},
        component: () => import('@/views/User/Profile.vue')
    },
    //group controller
    {
        path: '/usergroup',
        name: 'UserGroup',
        meta: { acl: [ 'UserGroup/List' ],},
        component: () => import('@/views/UserGroup/Index.vue')
    },
    //peak controller
    {
        path: '/peakmonth',
        name: 'PeakMonth',
        meta: { acl: [ 'PeakMonth/List' ],},
        component: () => import('@/views/PeakMonth/Index.vue')
    },
    //product controller
    {
        path: '/product',
        name: 'Product',
        meta: { acl: [ 'Product/View' ],},
        component: () => import('@/views/Product/Index.vue')
    },
    //Inventory controller
    {
        path: '/inventory',
        name: 'Inventory',
        meta: { acl: [ 'Inventory/Upload' ],},
        component: () => import('@/views/Inventory/Index.vue')
    },
    //DoanhSo controller
    {
        path: '/Revenue',
        name: 'Revenue',
        meta: { acl: [ 'Revenue/Upload' ],},
        component: () => import('@/views/Revenue/Index.vue')
    },
    //ImportOrder controller
    
    {
        path: '/ImportOrder/Edit/:id',
        name: 'ImportOrderEdit',
        meta: { acl: [ 'ImportOrder/Edit' ],},
        component: () => import('@/views/ImportOrder/Edit.vue')
    },
    {
        path: '/ImportOrder',
        name: 'ImportOrder',
        meta: { acl: [ 'ImportOrder/List' ],},
        component: () => import('@/views/ImportOrder/Index.vue')
    },
    //SalesForeCast controller
    {
        path: '/SalesForecast',
        name: 'SalesForecast',
        meta: { acl: [ 'SalesForecast/View' ],},
        component: () => import('@/views/SalesForecast/View.vue')
    },
    //GroupSale controller
    {
        path: '/GroupSale',
        name: 'GroupSale',
        meta: { acl: [ 'GroupSale/List' ],},
        component: () => import('@/views/GroupSale/Index.vue')
    },
    //SalesForeCast controller
    {
        path: '/Nienvu',
        name: 'Nienvu',
        meta: { acl: ['Nienvu/View'] },
        component: () => import('@/views/Nienvu/View.vue')
    },
    {
        path: '/Zones',
        name: 'Zones',
        meta: { acl: ['Zones/Edit'] },
        component: () => import('@/views/Zones/Index.vue')
    },
    {
        path: '/ProductType',
        name: 'ProductType',
        meta: { acl: ['ProductType/Edit'] },
        component: () => import('@/views/ProductType/Index.vue')
    },
    //Resource Controller
    {
        path: '/Resource/BangGia',
        name: 'ResourceBangGia',
        meta: {
            acl: ['Resource/BangGiaDownload', 'Resource/BangGiaUpload',],
            resource: 'BangGia'
        },
        component: () => import('@/views/Resource/BangGia.vue')
    },
    {
        path: '/Resource/HamperPrice2024',
        name: 'ResourceHamperPrice2024',
        meta: {
            acl: ['Resource/HamperPrice2024Download', 'Resource/HamperPrice2024Upload',],
            resource: 'HamperPrice2024'
        },
        component: () => import('@/views/Resource/CatalogRuou.vue')
    },
    {
        path: '/Resource/CatalogRuou',
        name: 'ResourceCatalogRuou',
        meta: {
            acl: ['Resource/CatalogRuouDownload', 'Resource/CatalogRuouUpload',],
            resource:'CatalogRuou'
        },
        component: () => import('@/views/Resource/CatalogRuou.vue')
    },
    {
        path: '/Resource/CatalogSake',
        name: 'ResourceCatalogSake',
        meta: {
            acl: ['Resource/CatalogSakeDownload', 'Resource/CatalogSakeUpload',],
            resource: 'CatalogSake'
        },
        component: () => import('@/views/Resource/CatalogSake.vue')
    },
    {
        path: '/Resource/Hosonangluc',
        name: 'ResourceHosonangluc',
        meta: {
            acl: ['Resource/HosonanglucDownload', 'Resource/HosonanglucUpload',],
            resource: 'Hosonangluc'
        },
        component: () => import('@/views/Resource/Hosonangluc.vue')
    },
    {
        path: '/Resource/Training',
        name: 'ResourceTraining',
        meta: {
            acl: ['Resource/TrainingDownload', 'Resource/TrainingUpload',],
            resource: 'Training'
        },
        component: () => import('@/views/Resource/Training.vue')
    },
    {
        path: '/Resource/TastingNote',
        name: 'ResourceTastingNote',
        meta: {
            acl: ['Resource/TastingNoteDownload', 'Resource/TastingNoteUpload',],
            resource: 'TastingNote'
        },
        component: () => import('@/views/Resource/TastingNote.vue')
    },
    {
        path: '/Resource/MarketingEvent',
        name: 'ResourceMarketingEvent',
        meta: {
            acl: ['Resource/MarketingEventDownload', 'Resource/MarketingEventUpload',],
            resource: 'MarketingEvent'
        },
        component: () => import('@/views/Resource/MarketingEvent.vue')
    },
    {
        path: '/SupplierOrderTracking',
        name: 'SupplierOrderTracking',
        meta: {},
        component: () => import('@/views/SupplierOrderTracking/Index.vue')
    },
    {
        path: '/CurrentPromotion',
        name: 'CurrentPromotion',
        meta: {},
        component: () => import('@/views/CurrentPromotion/Index.vue')
    },
    {
        path: '/ViewFeedback',
        name: 'ViewFeedback',
        meta: {},
        component: () => import('@/views/ViewFeedback/Index.vue')
    },
    {
        path: '/EnterFeedback',
        name: 'EnterFeedback',
        meta: {},
        component: () => import('@/views/EnterFeedback/Index.vue')
    },
    {
        path: '/DetailedImportOrder',
        name: 'DetailedImportOrder',
        meta: {},
        component: () => import('@/views/DetailedImportOrder/Index.vue')
    },
    {
        path: '/ProductDetail',
        name: 'ProductDetail',
        meta: {
            acl: ['ProductDetail/View']
        },
        component: () => import('@/views/Hamper/ProductDetail/Index.vue')
    },
    {
        path: '/ProductPDF',
        name: 'ProductPDF',
        meta: {
            acl: ['Product/PDFGenerate']
        },
        component: () => import('@/views/ProductPDF/Index.vue')
    },
    {
        path: '/HamperBox',
        name: 'HamperBox',
        meta: {
            acl: ['HamperBox/View']
        },
        component: () => import('@/views/Hamper/BoxDetail/Index.vue')
    },
    {
        path: '/HamperBoxPDF',
        name: 'HamperBoxPDF',
        meta: {
            acl: ['HamperBox/PDFGenerate']
        },
        component: () => import('@/views/Hamper/BoxDetail/Pdf.vue')
    },
    {
        path: '/HamperItem',
        name: 'HamperItem',
        meta: {
            acl: ['HamperItem/View']
        },
        component: () => import('@/views/Hamper/HamperItem/Index.vue')
    },
    {
        path: '/HamperItemPDF',
        name: 'HamperItemPDF',
        meta: {
            acl: ['HamperItem/PDFGenerate']
        },
        component: () => import('@/views/Hamper/HamperItem/Pdf.vue')
    },
    {
        path: '/Hamper',
        name: 'Hamper',
        meta: {
            acl: ['Hamper/View']
        },
        component: () => import('@/views/Hamper/Hamper/Index.vue')
    },
    {
        path: '/HamperTracking',
        name: 'HamperTracking',
        meta: { acl: ['Hamper/Tracking'] },
        component: () => import('@/views/Hamper/HamperTracking/Index.vue')
    },
    {
        path: '/Config',
        name: 'Config',
        meta: {
            acl: ['Config/Read']
        },
        component: () => import('@/views/Setting/index.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
//router.beforeEach((to, from, next) => {
router.beforeEach((to, from, next) => {
    //skip check for these routes
    document.title = 'PhuAdmin-' + to.name;

    if (to.fullPath == '/login') {        
        next();
        return;
    }
     //if not check login status then cancel the route
    //and wait for action after checklogin in App.vue
    if (!store.state.view.isCheckedLogin) {
        store.dispatch('view/setRedirectCheckLogin', to.fullPath);
        next(false);
        return;
    }

    //set resource name if route to resource
    //if (to.fullPath.indexOf('/resource/')==0 {
    //    next();
    //    return;
    //}
    
    if (to.meta.acl!=undefined && to.meta.acl != '') {
        //check acl
        let permission = false;
        for (let i = 0; i < to.meta.acl.length; i++) {
            permission = store.state.session.acl.includes(to.meta.acl[i]);
            if (permission) break;
        }
        if (!permission) {
            store.dispatch('view/showToast', { title: '', content: 'You have no permission!', type: 'error' });
            next(false);
        }
        else
            next();
    } else
        next();
    window.scrollTo(0, 0)
    
    // ...
})

export default router
