<template>
    <main :class="layoutClass">

        <Spinner :show="loading"></Spinner>
        <Toast></Toast>
        <SideBar v-model="isDefaultCollapsed"></SideBar>
        <PageTop v-model="isDefaultCollapsed"></PageTop>
        <div :class="mainContentClass" :style="mainStyle">
            <router-view />
        </div>
        <div>
            <i @click="moveTop" class="fa fa-angle-up back-top white" id="backTop" title="Back to Top" style="right: 5px; bottom: 40px; position: fixed; display: none;font-size:45px;"></i>
        </div>

        <Modal></Modal>
    </main>
</template>
<style>
    @import "./assets/style/vendor.css";
    @import "./assets/style/app.css";
    @import "./assets/style/custom.css";
    
</style>
<script>
    import SideBar from './components/SideBar.vue'
    import PageTop from './components/PageTop.vue'
    import Spinner from './components/Spinner.vue'
    import Toast from './components/Toast.vue'
    import { mapState, mapActions } from 'vuex'
    import $ from 'jquery'
    export default {
        name: `App`,
        components: { SideBar, PageTop, Spinner,Toast },
        data() {
            return {
                user: null,
                mainStyle:'',
                isDefaultCollapsed: false,
            };
        },
        computed: mapState({
            loading: state => state.view.loading,
            token: state => state.session.token,
            layoutClass: state => state.view.layoutClass,
            mainContentClass: state => state.view.layoutClass == 'auth-main' ? 'auth-block' : 'al-main'
        }),
        created() {
            console.log('App created');            
            //mark flag checklogin=false then flag as true if login success 
            //to activate the functions behide logged in
            //for refresh/reload page action
            this.setCheckLogin(false);
            this.checkLogin();

            //activate timeloop to check if user is forced to logout 
            this.initCheckAlive({ router: this.$router });
        },
        methods: {
            ...mapActions({
                'loginSession': 'session/loginSession',
                'setSession': 'session/setSession',
                'initCheckAlive': 'session/initCheckAlive',
                'setCheckLogin': 'view/setCheckLogin',
                'setLayoutClass': 'view/setLayoutClass',
                'setLoading': 'view/setLoading'
            }),

            changeLayout() {
                //set default sidebar collapsed
                $('.al-sidebar').css('background-color', 'rgba(0, 0, 0, .9)');
                if (this.isDefaultCollapsed) {                    
                    this.setLayoutClass('menu-collapsed');                    
                } else {                    
                    this.setLayoutClass('');
                    if (window.innerWidth > 500) {                        
                        $('.al-sidebar').css('background-color', 'rgba(0, 0, 0, .5)');
                    }
                }              

                let mrgLeft = 0;
                //if screen > 500 and not login then set margin left for page content
                if (window.innerWidth > 500) {
                    if (this.isDefaultCollapsed) mrgLeft = 50;
                    else mrgLeft = 180                    
                }
                this.mainStyle = `margin-left:${mrgLeft}px`;
                //force unset mainStyle if is login page
                if (document.location.pathname.indexOf('/login') == 0) {
                    this.mainStyle = '';
                }
                
            },
            moveTop: () => {
                $('html,body').animate({ scrollTop: 0 })
            },
            async checkLogin() {
                // check user loggedin
                let isLogged = true;
                if (this.token == null || typeof (this.token) == "undefined" || this.token == "") {

                    isLogged = false;
                    //check localstorage
                    const sex = localStorage.getItem('sex');
                    if (sex != "") {
                        //login with session
                        this.setLoading(true);
                        const rs = await this.loginSession({ Session: sex });
                        this.setLoading(false);
                        if (rs.Error == "") {
                            //set session and redirect to dashboard
                            this.setSession(rs.Data);
                            isLogged = true;
                        }
                    }

                }
                if (!isLogged) this.$router.push('login');
                else {
                    this.setCheckLogin(true);
                    //redirect to router after check login
                    this.$router.push(this.$store.state.view.redirectCheckLogin);
                }
                //
            },
            toggleElement(elId) {
                const paEl = event.target.parentElement;
                if (paEl.id==elId && $('#' + elId).next().css('display') == 'none')
                    $(paEl.nextSibling).show();
                else
                    $('#' + elId).next().hide();
            }
        },
        mounted() {
            console.log('App mounted');
            if (window.innerWidth < 1200) {
                this.isDefaultCollapsed = true;                
            } else {
                this.isDefaultCollapsed = false;                
            }
            this.changeLayout();
            //scroll event
            window.addEventListener('scroll', () => {
                if (window.scrollY >= 50 && document.location.pathname.indexOf("/login")<0) {
                    $('#backTop').css('display', 'inline');
                    $('.page-top').css('background-color', 'rgba(0, 0, 0, .8)');
                } else {
                    $('#backTop').css('display', 'none');
                    $('.page-top').css('background-color', 'rgba(0, 0, 0, .5)');
                }
            })

            //body click remove all popup and dropdown
            //const _this = this;
            //$('body').on('click', (e) => {
                
            //    _this.toggleElement('msg-toggle-link');
            //    _this.toggleElement('notify-toggle-link');
            //    //console.log(e);
            //    $(".dropdown-menu").each((_, item) => {                   
            //        if (e.target !== $(item).parent().get(0)
            //            && e.target !== $(item).prev().get(0)) {
            //            $(item).css("display", "none");
            //        }
            //    });
            //    //console.log($($(".dropdown-menu").get(1)).parent().get(0));
            //    //$(".dropdown-menu").css("display", "none");
            //});
            //document.getElementsByClassName('dropdown-menu')[0].style.display = 'none';
            
        },
        watch: {
            layoutClass() {
                

            },
            isDefaultCollapsed() {
                this.changeLayout();               
            },
            $route(to, from) {                
                if (to.name == "Login") {
                    this.setLayoutClass('auth-main');
                    this.mainStyle = '';
                    this.changeLayout();
                }
                else if (from.name == "Login") {
                    this.setLayoutClass('');
                    this.changeLayout();
                }
                //menu selected autoset
            }

        }

    };
</script>

